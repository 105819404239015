import DataTable from "react-data-table-component";
import { Themes } from "../../common/Constant";
import { GetAdminMemberList } from "../../common/Request";
import { Navigation } from "./Navigation";
import { UserDetails } from "../UserDetails";
import { UserWallet } from "../UserWallet";
import { CartForm } from "./form/CartForm";
import { EarningHistory } from "./component/EarningHistory";
import { useEffect, useState, useMemo } from "react";
import searchIcon from "../../assets/img/search.svg";
import detailsIcon from "../../assets/img/user-details.svg";
import cartIcon from "../../assets/img/cart.svg";
import walletIcon from "../../assets/img/wallet.svg";
import historyIcon from "../../assets/img/history.svg";
import { useStoreState } from "../../common/Store";

export const AdminMemberList = () => {

    const userInfo = useStoreState("userinfo");
    const [ details, setDetails ] = useState({});
    const [ showDetails, setShowDetails ] = useState(false);
    const [ cartObj, setCartObj ] = useState({ mid: 0, name: '' });
    const [ showCart, setShowCart ] = useState(false);
    const [ walletObj, setWalletObj ] = useState({ mid: 0, name: '' });
    const [ showWallet, setShowWallet ] = useState(false);
    const [ showHistory, setShowHistory ] = useState(false);
    const [ historyObj, setHistoryObj ] = useState({ mid: 0, name: '' });

    const showMemberDetails = (d) => {
        setDetails(d);
        setShowDetails(true);
    }

    const hideMemberDetails = (reload = false) => {
        setShowDetails(false);
        if(reload) {
            GetAdminMemberList(res => {
                setData(res);
            });
        }
    }

    const showMemberCart = (mid, name) => {
        // setDetails(d);
        setCartObj({ mid: mid, name: name });
        setShowCart(true);
    }

    const hideMemberCart = () => {
        setShowCart(false);
    }

    const showMemberWallet = (mid, name) => {
        setWalletObj({ mid: mid, name: name });
        setShowWallet(true);
    }

    const hideMemberWallet = () => {
        setShowWallet(false);
    }

    const showEarningHistory = (mid, name) => {
        setHistoryObj({ mid: mid, name: name })
        setShowHistory(showHistory ? false : true);
    }

    const columns = [
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
        },
        {
            name: 'Code',
            selector: row => row.code,
            width: '100px',
            sortable: true,
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
        },
        {
            name: 'Account Type',
            selector: row => <b style={{ color: row.type === "Reseller" ? "green" : "rgb(245 158 11)" }}>{ row.type }</b>,
            width: '130px',
            sortable: true,
        },
        {
            name: 'Action',
            selector: row => <div>
                    <img src={ detailsIcon } onClick={ () => { showMemberDetails(row); } } className={ Themes.ImageButton } alt="detailsicon" />
                    <img src={ walletIcon } onClick={ () => { showMemberWallet(row.mid, row.name); } } className={ Themes.ImageButton } alt="walleticon" />
                    <img src={ historyIcon } onClick={ () => { showEarningHistory(row.mid, row.name); } } className={ Themes.ImageButton } alt="historyicon" />
                    <img src={ cartIcon } onClick={ () => { showMemberCart(row.mid, row.name); } } className={ Themes.ImageButton } alt="carticon" />
                </div>,
            width: '195px',
            hide: userInfo.type === "Cashier" ? 40000 : 1
        },
        {
            name: 'Created',
            selector: row => row.created,
            sortable: true,
        },
    ];

    const [ data, setData ] = useState([]);

    const [filterText, setFilterText] = useState('');
    const filteredItems = userInfo.type === "Cashier" ? data.filter(
		item => (item.name.toLowerCase().includes(filterText.toLowerCase()) || 
                item.code.toLowerCase().includes(filterText.toLowerCase()) || 
                item.email.toLowerCase().includes(filterText.toLowerCase()))
                && filterText !== ""
	) : data.filter(
		item => item.name.toLowerCase().includes(filterText.toLowerCase()) || 
                item.code.toLowerCase().includes(filterText.toLowerCase()) || 
                item.email.toLowerCase().includes(filterText.toLowerCase())
	);

    const subHeaderComponentMemo = useMemo(() => {
		return (
            <div className="relative">
                <img src={ searchIcon } className={ Themes.InputIcon } alt="searchicon" />
                <input type="text" className={ Themes.InputField } placeholder="Filter By Code/Name/Email" value={ filterText } onChange={ (e) => { setFilterText(e.target.value) } } />
            </div>
		);
	}, [filterText]);

    const onUpdate = () => {
        GetAdminMemberList(res => {
            setData(res);
        });
    }

    useEffect(() => {
        GetAdminMemberList(res => {
            setData(res);
        });
    }, [])

    return (
        <div className="relative flex">
            <Navigation />
            <div className="w-full h-screen p-8">
                <DataTable
                    title="Member List"
                    pagination
                    columns={ columns }
                    data={ filteredItems }
                    subHeader
                    subHeaderComponent={ subHeaderComponentMemo }
                />
            </div>
            <UserDetails data={ details } show={ showDetails } hide={ hideMemberDetails } onupdate={ onUpdate } />
            <UserWallet data={ walletObj } show={ showWallet } hide={ hideMemberWallet } />
            <CartForm data={ cartObj } show={ showCart } hide={ hideMemberCart } />
            <EarningHistory mid={ historyObj.mid } name={ historyObj.name } show={ showHistory } onClose={ () => { setShowHistory(false) } } />
        </div>
    )
}