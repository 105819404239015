import { dispatch } from "../common/Store";
import { Themes } from "../common/Constant";
import { MemberLogin, MemberForgotPassword } from "../common/Request";
import { ShowAlert } from "../component/UI/Alert";
import logo from "../assets/img/logo.png";
import userIcon from "../assets/img/user.svg";
import lockIcon from "../assets/img/lock.svg";
import { useRef, useState } from "react";

export const Login = () => {

    const [ loginPage, setLoginPage ] = useState(true);
    const emailRef = useRef();
    const passwordRef = useRef();
    const forgotEmailRef = useRef();

    const onSubmit = (e) => {
        MemberLogin({ email: emailRef.current.value, password: passwordRef.current.value }, res => {
            if(res.t !== "Success") {
                ShowAlert(res.t, res.m);
            }else if(res.type === "Member" || res.type === "Reseller"){
                dispatch({ type: "userinfo", data: res });
                dispatch({ type: "page", data: "memberdashboard" });
            }else if(res.type === "Admin" || res.type === "Cashier"){
                dispatch({ type: "userinfo", data: res });
                dispatch({ type: "page", data: res.type === "Admin" ? "admindashboard" : "adminmemberlist" });
            }
        });
        e.preventDefault();
    }

    const forgotPassword = (e) => {
        MemberForgotPassword({ email: forgotEmailRef.current.value }, res => {
            if(res.t === "Success") {
                ShowAlert("Success", "A reset password link was sent to your email.");
            }else{
                ShowAlert(res.t, res.m);
            }
        })
        e.preventDefault();
    }

    return (
        <div className="bg-white max-w-96 w-screen block m-auto px-4 py-2 drop-shadow-lg">
            <img src={ logo } className="block h-24 p-2 m-auto mb-2" alt="logo" />
            {
                loginPage ?
                <div>
                    <h1 className="text-lg">SIGN IN</h1>
                    <form onSubmit={ (e) => { onSubmit(e); } }>
                        <div className="relative">
                            <img src={ userIcon } className={ Themes.InputIcon } alt="usericon" />
                            <input ref={ emailRef } type="text" className={ Themes.InputField } placeholder="Email Address" required />
                        </div>
                        <div className="relative mb-2">
                            <img src={ lockIcon } className={ Themes.InputIcon } alt="passicon" />
                            <input ref={ passwordRef } type="password" className={ Themes.InputField } placeholder="Password" required />
                        </div>
                        <button type="submit" className={ Themes.FilledButton }>Sign in</button>
                        <button type="button" className={ Themes.OutlineButton } onClick={ () => { dispatch({ type: "page", data: "register" }); } }>Sign up</button>

                        <button type="button" className="block text-blue-700 italic mx-auto mt-4 mb-2" onClick={ () => { setLoginPage(false) } }>Forgot your password?</button>
                    </form>
                </div>
                :
                <div>
                    <h1 className="text-lg">FORGOT PASSWORD?</h1>
                    <form onSubmit={ (e) => { forgotPassword(e); } }>
                        <div className="relative">
                            <img src={ userIcon } className={ Themes.InputIcon } alt="usericon" />
                            <input ref={ forgotEmailRef } type="text" className={ Themes.InputField } placeholder="Email Address" required />
                        </div>
                        <button type="submit" className={ Themes.FilledButton }>Send Link</button>
                        <button type="button" className={ Themes.OutlineButton } onClick={ () => { setLoginPage(true) } }>Back to Login</button>
                    </form>
                </div>
            }
            
            
        </div>
    )

}