import moment from "moment";
import { useSpring, animated  } from "@react-spring/web";
import { Themes } from "../common/Constant";
import { formatMoney } from "../common/Utils";
import { GetWallet, AdminUpdateMemberPoints } from "../common/Request";
import { ShowAlert } from "./UI/Alert";
import balanceIcon from "../assets/img/wallet.svg";
import pointsIcon from "../assets/img/points.svg";
import closeIcon from "../assets/img/white-x.svg";
import { useEffect, useRef, useState } from "react";

export const UserWallet = ({ data = {}, show = false, hide }) => {

    const [ wallet, setWallet ] = useState({amount: 0, rpb: 0});
    const [ key, setKey ] = useState("user_" + moment().format("x"));
    const pointsRef = useRef();
    const vouchersRef = useRef();

    const [ animProps, anim ] = useSpring(
        () => ({ 
            from: { y: -20, opacity: 0 },
            to: { y: 0 , opacity: 1 },
            config: {
                duration: 150
            }
        }), 
    []);

    const close = () => {
        anim.start({
            to: { y: -20, opacity: 0 },
            onResolve: () => {
                hide();
            }
        })
    }

    const onSubmit = (e) => {
        AdminUpdateMemberPoints({ mid: data.mid, points: pointsRef.current.value, loyalty: vouchersRef.current.value }, res => {
            ShowAlert("Success", "Successfully updated!");
        })
        e.preventDefault();
    }

    useEffect(() => {
        if(show) {
            anim.start({
                to: { y: 0, opacity: 1 }
            });

            if(data.mid > 0) {
                GetWallet({ mid: data.mid }, res => {
                    setWallet(res);
                    setKey("user_" + data.mid + "_" + moment().format("x"));
                })
            }
        }
    }, [ show, anim, data ])

    return (
        <div className={ "fixed inset-0 w-screen h-screen bg-slate-700/50 " + (show ? "visible" : "invisible") }>
            <animated.div className="max-w-96 w-screen m-auto bg-white drop-shadow-lg" style={ animProps }>
                <h1 className={ Themes.Header }>
                    { data.name }'s Wallet
                    <img src={ closeIcon } className="absolute top-1 right-2 w-8 cursor-pointer" onClick={ () => { close(); } } alt="closeicon" />    
                </h1>
                <form onSubmit={ (e) => { onSubmit(e); } } className="p-2" key={ key }>
                    <h5 className="text-sm font-bold">Wallet Balance:</h5>
                    <div className="relative">
                        <img src={ balanceIcon } className={ Themes.InputIcon } alt="walleticon" />
                        <input type="text" className={ Themes.InputField + " bg-gray-100" } defaultValue={ formatMoney(wallet.amount) } readOnly />
                    </div>
                    <div className="my-2 grid grid-cols-2 gap-2">
                        <div>
                            <h5 className="text-sm">PEB: <span className="font-bold">{ formatMoney(wallet.peb) }</span></h5>
                            <h5 className="text-sm">PPPB: <span className="font-bold">{ formatMoney(wallet.pppb) }</span></h5>
                            <h5 className="text-sm">PSB: <span className="font-bold">{ formatMoney(wallet.psb) }</span></h5>
                            <h5 className="text-sm">RB: <span className="font-bold">{ formatMoney(wallet.rb) }</span></h5>
                        </div>
                        <div>
                            <h5 className="text-sm">CB: <span className="font-bold">{ formatMoney(wallet.cb) }</span></h5>
                            <h5 className="text-sm">GLB: <span className="font-bold">{ formatMoney(wallet.glb) }</span></h5>
                            <h5 className="text-sm">GTB: <span className="font-bold">{ formatMoney(wallet.gtb) }</span></h5>
                            <h5 className="text-sm">GCB: <span className="font-bold">{ formatMoney(wallet.gcb) }</span></h5>
                        </div>
                    </div>
                    
                    <h5 className="text-sm font-bold">Reward Points Bonus:</h5>
                    <div className="relative">
                        <img src={ pointsIcon } className={ Themes.InputIcon } alt="pointsicon" />
                        <input type="text" ref={ pointsRef } className={ Themes.InputField } defaultValue={ wallet.rpb } required />
                    </div>

                    <h5 className="text-sm font-bold">Buy 1 Take 1 Vouchers:</h5>
                    <div className="relative">
                        <img src={ pointsIcon } className={ Themes.InputIcon } alt="pointsicon" />
                        <input type="text" ref={ vouchersRef } className={ Themes.InputField } defaultValue={ wallet.loyalty } required />
                    </div>
                    <button type="submit" className={ Themes.FilledButton }>Save Changes</button>
                </form>
            </animated.div>
        </div>
    )
}