import { getUrl } from "./Utils";

const requestOptions = (body) => {
    const formData = new FormData();
    Object.keys(body).forEach(key => {
        formData.append(key, body[key]);
    })

    return {
        method: "POST",
        headers: {
            'Access-Control-Allow-Origin': '*',
        },
        body: formData
    }
}

const request = async (api, body, callback) => {
    await fetch(getUrl(api), requestOptions(body)).then(response => response.json()).then(callback);
}

export const CreateMember = (obj, callback) => {
    request("Member/Register", {
        sponsor: obj.sponsor,
        upline: obj.upline,
        pos: obj.position,
        name: obj.name,
        phone: obj.phone,
        email: obj.email,
        address: obj.address,
        password: obj.password,
    }, callback);
}

export const MemberLogin = (obj, callback) => {
    request("Member/Login", {
        email: obj.email,
        password: obj.password
    }, callback);
}

export const MemberForgotPassword = (obj, callback) => {
    request("Member/SendForgotPassword", { email: obj.email }, callback);
}

export const MemberGetForgotPasswordDetails = (obj, callback) => {
    request("Member/GetForgotPasswordDetails", { code: obj.code }, callback);
}

export const MemberResetPassword = (obj, callback) => {
    request("Member/ResetPassword", { code: obj.code, password: obj.password }, callback);
}

export const MemberUpdateDetails = (obj, callback) => {
    let data = { mid: obj.mid, name: obj.name, email: obj.email, address: obj.address, phone: obj.phone };
    if(obj.hasOwnProperty('password')) {
        data.password = obj.password;
    }
    request("Member/UpdateDetails", data, callback);
}

export const MemberMyStore = (obj, callback) => {
    request("Member/MyStore", { mid: obj.mid }, callback);
}

export const MemberMyStoreInventory = (obj, callback) => {
    request("Member/MyStoreGetInventory", { mid: obj.mid }, callback);
}

export const MemberMyStoreTransactions = (obj, callback) => {
    request("Member/MyStoreTransactions", { mid: obj.mid }, callback);
}

export const MemberMyStoreUpdateDeliveryStatus = (obj, callback) => {
    request("Member/MyStoreUpdateDeliveryStatus", { pid: obj.pid, status: obj.status }, callback);
}

export const MemberMyStoreDeclinePurchase = (obj, callback) => {
    request("Member/MyStoreDeclinePurchase", { pid: obj.pid }, callback);
}

export const MemberMyStoreConfirmPurchase = (obj, callback) => {
    request("Member/MyStoreConfirmPurchase", { pid: obj.pid }, callback);
}

export const MemberMyStoreProductsAndPackages = (callback) => {
    request("Member/MyStoreProductsAndPackages", {}, callback);
}

export const MemberMyStoreCheckout = (obj, callback) => {
    request("Member/MyStoreCheckout", { 
        mid: obj.mid, 
        storeobj: obj.storeobj,
        delivery: obj.delivery,
        name: obj.name,
        address: obj.address,
        phone: obj.phone,
        modepayment: obj.modepayment,
        paymentmethod: obj.paymentmethod,
        refnum: obj.refnum,
        screenshot: obj.screenshot,
        note: obj.note,
        otp: obj.otp,
        subtotal: obj.subtotal,
        shipping: obj.shipping,
        fee: obj.fee,
        total: obj.total,
        cashier: obj.cashier
    }, callback);
}

export const GetAvailableStore = (obj, callback) => {
    request("Member/GetAvailableStores", {
        data: JSON.stringify(obj.data)
    }, callback);
}

export const StorePayoutRequest = (obj, callback) => {
    request("Member/MyStorePayoutRequest", {
        mid: obj.mid,
        method: obj.method,
        acctname: obj.acctname,
        acctnum: obj.acctnum,
        otp: obj.otp
    }, callback);
}

export const GetBinary = (obj, callback) => {
    request("Member/Binary", {
        mid: obj.mid
    }, callback);
}

export const GetDirects = (obj, callback) => {
    request("Member/Directs", {
        mid: obj.mid
    }, callback);
}

export const GetWallet = (obj, callback) => {
    request("Member/Wallet", {
        mid: obj.mid
    }, callback);
}

export const GetPackages = (obj, callback) => {
    request("Packages", {
        mid: obj.mid
    }, callback);
}

export const PackageCheckout = (obj, callback) => {
    request("Packages/Checkout", {
        mid: obj.mid,
        paid: obj.paid,
        delivery: obj.delivery,
        name: obj.name,
        address: obj.address,
        phone: obj.phone,
        modepayment: obj.modepayment,
        paymentmethod: obj.paymentmethod,
        refnum: obj.refnum,
        screenshot: obj.screenshot,
        note: obj.note,
        otp: obj.otp,
        store: obj.store,
        cashier: obj.cashier
    }, callback);
}

export const GetPurchases = (obj, callback) => {
    request("Purchases/History", {
        mid: obj.mid
    }, callback);
}

export const GetHistory = (obj, callback) => {
    request("History", {
        mid: obj.mid
    }, callback);
}

export const GetPurchaseDetails = (obj, callback) => {
    request("Purchases/Details", {
        pid: obj.pid
    }, callback);
}

export const SendOTPOtherWallet = (obj, callback) => {
    request("Member/SendOTPOtherWallet", {
        mid: obj.mid,
        email: obj.email
    }, callback);
}

export const CheckOTPOtherWallet = (obj, callback) => {
    request("Member/CheckOTPOtherWallet", {
        mid: obj.mid,
        otp: obj.otp
    }, callback);
}

export const GetPayouts = (obj, callback) => {
    request("Member/Payouts", {
        mid: obj.mid
    }, callback);
}

export const SendPayoutOTP = (obj, callback) => {
    request("Member/SendOTPPayout", {
        mid: obj.mid
    }, callback);
}

export const PayoutRequest = (obj, callback) => {
    request("Member/PayoutRequest", {
        mid: obj.mid,
        method: obj.method,
        acctname: obj.acctname,
        acctnum: obj.acctnum,
        otp: obj.otp
    }, callback);
}

export const GetProducts = (callback) => {
    request("Member/Products", {}, callback);
}

export const GetDiscount = (obj, callback) => {
    request("Member/Discount", { mid: obj.mid }, callback);
}

export const GetCashiers = (callback) => {
    request("Member/GetOTC", {}, callback);
}

export const GetCart = (obj, callback) => {
    request("Member/Cart", { mid: obj.mid }, callback);
}

export const AddCart = (obj, callback) => {
    request("Member/AddCart", { mid: obj.mid, prid: obj.prid, free: obj.free, price: obj.price, name: obj.name, points: obj.points }, callback);
}

export const UpdateCart = (obj, callback) => {
    request("Member/UpdateCart", { mid: obj.mid, prid: obj.prid, qty: obj.qty }, callback);
}

export const CheckoutCart = (obj, callback) => {
    request("Member/CheckoutCart", { 
        mid: obj.mid, 
        delivery: obj.delivery,
        name: obj.name,
        address: obj.address,
        phone: obj.phone,
        modepayment: obj.modepayment,
        paymentmethod: obj.paymentmethod,
        refnum: obj.refnum,
        screenshot: obj.screenshot,
        note: obj.note,
        otp: obj.otp,
        store: obj.store,
        cashier: obj.cashier
    }, callback);
}

export const RenewCheckout = (obj, callback) => {
    request("Member/RenewCheckout", { 
        mid: obj.mid, 
        prids: obj.prids, 
        delivery: obj.delivery,
        name: obj.name,
        address: obj.address,
        phone: obj.phone,
        modepayment: obj.modepayment,
        paymentmethod: obj.paymentmethod,
        refnum: obj.refnum,
        screenshot: obj.screenshot,
        note: obj.note,
        otp: obj.otp,
        store: obj.store,
        cashier: obj.cashier
    }, callback);
}

export const RewardProducts = (callback) => {
    request("Member/RewardProducts", {}, callback);
}

export const GetMyRewards = (obj, callback) => {
    request("Member/MyRewards", { mid: obj.mid }, callback);
}

export const RedeemRewards = (obj, callback) => {
    request("Member/RedeemRewards", { mid: obj.mid, rdids: obj.rdids, type: obj.type }, callback);
}

// ADMIN
export const GetAdminMonitoring = (callback) => {
    request("Admin/Monitoring", {}, callback);
}

export const GetAdminMemberList = (callback) => {
    request("Admin/MemberList", {}, callback);
}

export const GetAdminMemberDetails = (obj, callback) => {
    request("Admin/MemberDetails", { mid: obj.mid }, callback);
}

export const AdminUpgradeMember = (obj, callback) => {
    request("Admin/MemberUpgradeAccount", { mid: obj.mid }, callback);
}

export const GetAdminMemberHistory = (callback) => {
    request("Admin/MemberHistory", {}, callback);
}

export const AdminUpgradeRank = (obj, callback) => {
    request("Admin/MemberUpgradeRank", { mid: obj.mid, rid: obj.rid }, callback);
}

export const AdminUpdateMemberDetails = (obj, callback) => {
    request("Admin/MemberUpdateDetails", { mid: obj.mid, name: obj.name, email: obj.email, address: obj.address, phone: obj.phone }, callback);
}

export const AdminUpdateMemberPoints = (obj, callback) => {
    request("Admin/MemberUpdatePoints", { mid: obj.mid, points: obj.points, loyalty: obj.loyalty }, callback);
}
export const GetAdminPackagePurchase = (callback) => {
    request("Admin/PackagePurchase", {}, callback);
}

export const AdminUpdateDeliveryStatus = (obj, callback) => {
    request("Admin/UpdateDeliveryStatus", { pid: obj.pid, status: obj.status }, callback);
}

export const AdminDeclinePurchase = (obj, callback) => {
    request("Admin/DeclinePurchase", { pid: obj.pid }, callback);
}

export const AdminConfirmPurchase = (obj, callback) => {
    request("Admin/ConfirmPurchase", { pid: obj.pid }, callback);
}

export const GetAdminRepeatPurchase = (callback) => {
    request("Admin/RepeatPurchase", {}, callback);
}

export const GetAdminProductInventory = (callback) => {
    request("Admin/ProductInventory", {}, callback);
}

export const GetAdminPayouts = (callback) => {
    request("Admin/Payouts", {}, callback);
}

export const AdminDeclinePayout = (obj, callback) => {
    request("Admin/PayoutDecline", { payid: obj.payid }, callback);
}

export const AdminConfirmPayout = (obj, callback) => {
    request("Admin/PayoutConfirm", { payid: obj.payid, refnum: obj.refnum, screenshot: obj.screenshot }, callback);
}

export const AdminAddProduct = (obj, callback) => {
    request("Admin/AddProduct", {
        name: obj.name,
        description: obj.description,
        price: obj.price,
        points: obj.points,
        storemin: obj.storemin,
        free: obj.free,
        photo: obj.photo
    }, callback);
}

export const AdminUpdateProduct = (obj, callback) => {
    request("Admin/UpdateProduct", {
        prid: obj.prid,
        name: obj.name,
        description: obj.description,
        price: obj.price,
        points: obj.points,
        storemin: obj.storemin,
        free: obj.free,
        photo: obj.photo
    }, callback);
}

export const AdminGetRewardProducts = (callback) => {
    request("Admin/RewardProducts", {}, callback);
}

export const AdminGetRedeemRequests = (callback) => {
    request("Admin/GetRedeemRequests", {}, callback);
}

export const AdminApproveRedemption = (obj, callback) => {
    request("Admin/ApproveRedemption", { rmid: obj.rmid }, callback);
}

export const AdminAddRewardItem = (obj, callback) => {
    request("Admin/AddRewardItem", {
        name: obj.name,
        category: obj.category,
        points: obj.points,
        price: obj.price,
        photo: obj.photo
    }, callback);
}

export const AdminEditRewardItem = (obj, callback) => {
    request("Admin/EditRewardItem", {
        rdid: obj.rdid,
        name: obj.name,
        category: obj.category,
        points: obj.points,
        price: obj.price,
        photo: obj.photo
    }, callback);
}

export const AdminDeleteRewardItem = (obj, callback) => {
    request("Admin/DeleteRewardItem", { rdid: obj.rdid }, callback);
}

export const GetAdminPackages = (callback) => {
    request("Admin/Packages", {}, callback);
}

export const AdminAddPackage = (obj, callback) => {
    request("Admin/AddPackage", {
        name: obj.name,
        prids: obj.prids,
        total: obj.total,
        points: obj.points,
        storemin: obj.storemin
    }, callback);
}

export const AdminUpdatePackage = (obj, callback) => {
    request("Admin/UpdatePackage", {
        paid: obj.paid,
        name: obj.name,
        prids: obj.prids,
        total: obj.total,
        points: obj.points,
        storemin: obj.storemin
    }, callback);
}

export const AdminGetAllStockist = (callback) => {
    request("Admin/StockistGetAll", {}, callback);
}

export const AdminAddStockist = (obj, callback) => {
    request("Admin/AddStockist", {
        email: obj.email,
        name: obj.name,
        address: obj.address
    }, callback);
}

export const AdminGetStockistPurchases = (callback) => {
    request("Admin/GetStockistPurchases", {}, callback);
}

export const GetPartners = (callback) => {
    request("Admin/GetPartners", {}, callback);
}

export const GetMemberPartners = (callback) => {
    request("Member/GetPartners", {}, callback);
}

export const AdminAddPartner = (obj, callback) => {
    request("Admin/AddPartner", { partner: obj.partner, photo: obj.photo }, callback);
}

export const AdminDeletePartner = (obj, callback) => {
    request("Admin/DeletePartner", { ptid: obj.ptid }, callback);
}

export const AdminManualCheckout = (obj, callback) => {
    request("Admin/MemberManualCheck", { 
        mid: obj.mid,
        cart: obj.cart,
        discount: obj.discount,
        shipping: obj.shipping,
        subtotal: obj.subtotal,
        total: obj.total,
        delivery: obj.delivery
    }, callback);
}

export const AdminGetAllCashiers = (callback) => {
    request("Admin/AllOTC", {}, callback);
}

export const AdminAddCashiers = (obj, callback) => {
    request("Admin/AddOTC", { name: obj.name, email: obj.email, password: obj.password }, callback);
}

export const AdminDeleteCashiers = (obj, callback) => {
    request("Admin/DeleteOTC", { mid: obj.mid }, callback);
}

export const UpdateSettings = (obj, callback) => {
    request("Admin/UpdateSettings", { 
        adminfee: obj.adminfee,
        otherfee: obj.otherfee,
        shippingfee: obj.shippingfee,
        safety: obj.safety,
        mrp: obj.mrp,
        storediscount: obj.storediscount,
        storepackdisc: obj.storepackdisc,
        cashierdaily: obj.cashierdaily
    }, callback);
}

export const GetAdminSettings = (callback) => {
    request("Admin/Settings", {}, callback);
}

export const GetAllBankAccounts = (obj, callback) => {
    request("BankAccount/GetAll", { mid: obj.mid }, callback);
}

export const AddBankAccount = (obj, callback) => {
    request("BankAccount/AddBankAccount", { 
        mid: obj.mid,
        bankname: obj.bankname,
        acctname: obj.acctname,
        acctnum: obj.acctnum
    }, callback);
}

export const DeleteBankAccount = (obj, callback) => {
    request("BankAccount/DeleteBankAccount", { 
        bankid: obj.bankid
    }, callback);
}