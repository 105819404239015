import { dispatch } from "../common/Store";
import { Themes } from "../common/Constant";
import { MemberGetForgotPasswordDetails, MemberResetPassword } from "../common/Request";
import { parseURLParam } from "../common/Utils";
import { ShowAlert } from "./UI/Alert";
import { useEffect, useRef, useState } from "react";
import logo from "../assets/img/logo.png";
import userIcon from "../assets/img/user.svg";
import lockIcon from "../assets/img/lock.svg";

export const ResetPassword = () => {

    const [ email, setEmail ] = useState();
    const [ code, setCode ] = useState();
    const passwordRef = useRef();
    const retypePasswordRef = useRef();

    const onSubmit = (e) => {
        if(passwordRef.current.value !== retypePasswordRef.current.value) {
            ShowAlert("Failed", "Passwords did not match.");
        }else{
            MemberResetPassword({ code: code, password: passwordRef.current.value }, res => {
                if(res.t === "Success") {
                    ShowAlert("Success", "Your password was reset.");
                    dispatch({ type: "page", data: "login" });
                }
            })
        }
        e.preventDefault();
    }

    useEffect(() => {
        const param = parseURLParam();
        setCode(param['forgotpassword']);
        MemberGetForgotPasswordDetails({ code: param['forgotpassword'] }, res => {
            setEmail(res.email);
        })
    }, [])

    return (
        <div className="bg-white max-w-96 w-screen block m-auto px-4 py-2 drop-shadow-lg">
            <img src={ logo } className="block h-24 p-2 m-auto mb-2" alt="logo" />
            <div>
                <h1 className="text-lg">RESET PASSWORD</h1>
                <form onSubmit={ (e) => { onSubmit(e); } }>
                    <div className="relative">
                            <img src={ userIcon } className={ Themes.InputIcon } alt="usericon" />
                            <input type="email" defaultValue={ email } className={ Themes.InputField } placeholder="Email Address" disabled />
                    </div>
                    <div className="relative mb-2">
                        <img src={ lockIcon } className={ Themes.InputIcon } alt="passicon" />
                        <input ref={ passwordRef } type="password" className={ Themes.InputField } placeholder="New Password" required />
                    </div>
                    <div className="relative mb-2">
                        <img src={ lockIcon } className={ Themes.InputIcon } alt="passicon" />
                        <input ref={ retypePasswordRef } type="password" className={ Themes.InputField } placeholder="Re-type Password" required />
                    </div>
                    <button type="submit" className={ Themes.FilledButton }>Reset</button>
                </form>
            </div>
        </div>
    )
}